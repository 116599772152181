import React from "react"

import "./Quote.css"

const Quote = ({ children, display }) => {
  let quoteType

  if (display === "info" || display === "warning") {
    quoteType = "quote-" + display
  } else {
    quoteType = "quote-default"
  }

  return (
    <div className="quote-wrapper">
      <div className={quoteType}>{children}</div>
    </div>
  )
}

export default Quote
