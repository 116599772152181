import React from "react"
import { AiOutlineLink } from "react-icons/ai"

import "./Headings.css"

const baseUrl = "#"

const myH2 = ({ children, id, title }) => {
  if (title) {
    return (
      <div className="my-h2-wrap">
        <h2 id={`${id}`} className="my-h2">
          {children}
          <a href={`${baseUrl}${id}`} className="h2-link">
            <AiOutlineLink />
          </a>
        </h2>
      </div>
    )
  }

  return <h2>{children}</h2>
}

const myH3 = ({ children, title }) => {
  if (title) {
    return (
      <div>
        <h3 className="my-h3">{children}</h3>
      </div>
    )
  }

  return <h3>{children}</h3>
}

const myH4 = ({ children, title }) => {
  if (title) {
    return (
      <div>
        <h4 className="my-h4">{children}</h4>
      </div>
    )
  }

  return <h4>{children}</h4>
}

const myH5 = ({ children, title }) => {
  if (title) {
    return (
      <div>
        <h4 className="my-h5">{children}</h4>
      </div>
    )
  }

  return <h4>{children}</h4>
}

const myH6 = ({ children, title }) => {
  if (title) {
    return (
      <div>
        <h4 className="my-h6">{children}</h4>
      </div>
    )
  }

  return <h4>{children}</h4>
}
export { myH2, myH3, myH4, myH5, myH6 }
