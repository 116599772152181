import React from "react"
import { Link as Scroll } from "react-scroll"

import "./SideMenu.css"

const SideMenu = ({ headId, headText }) => {
  const ids = headId.split(",")
  const texts = headText.split(",")
  let headings = []
  for (let i = 0; i < Math.max(ids.length, texts.length); i++) {
    const id = i < ids.length ? ids[i] : ""
    const text = i < texts.length ? texts[i] : ""
    headings.push([id, text])
  }

  return (
    <div className="post-sideMenu">
      <h2 className="indexTitle">Contents of Table</h2>
      <ul className="sideMenu">
        {headings.map((heading, index) => {
          return (
            <li key={index}>
              <Scroll
                to={`${heading[0]}`}
                spy={true}
                smooth={true}
                offset={-50}
              >
                {heading[1]}
              </Scroll>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default SideMenu
