import React from "react"
import { MDXProvider } from "@mdx-js/react"
import * as Headings from "./src/components/Elements/Headings/Headings"
import Quote from "./src/components/Elements/Quote/Quote"
import InlineCode from "./src/components/Elements/InlineCode/InlineCode"
import PrismSetup from "./src/components/Elements/PrismSetup/PrismSetup"
import SideMenu from "./src/components/Elements/SideMenu/SideMenu"

const components = {
  h2: Headings.myH2,
  h3: Headings.myH3,
  h4: Headings.myH4,
  h5: Headings.myH5,
  h6: Headings.myH6,
  inlineCode: InlineCode,
  code: InlineCode,
  quote: Quote,
  pre: PrismSetup,
  sideMenu: SideMenu,
}

export const wrapMDX = ({ element }) => {
  return <MDXProvider components={components}>{element}</MDXProvider>
}
