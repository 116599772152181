import React, { useState } from "react"
import Highlight, { defaultProps } from "prism-react-renderer"
import { AiOutlineExpand } from "react-icons/ai"

import theme from "prism-react-renderer/themes/vsDark"
// import theme from "prism-react-renderer/themes/oceanicNext"
// import theme from "prism-react-renderer/themes/duotoneDark"

import "./PrismSetup.css"

const PrismWrapper = props => {
  const className = props.children.props.className
  // const language = className.split("-")[1]
  const [isCodeOpen, setIsCodeOpen] = useState(false)

  // 以下の形式で来る.
  // language-{コードブロックで渡す値}
  // コードブロックで渡す値は {言語:ファイル名} の形式にする.
  const colonIndex = className.indexOf(":")
  const languageEnd = colonIndex !== -1 ? colonIndex : className.length
  const language = className.substring(className.indexOf("-") + 1, languageEnd)
  const filename =
    colonIndex !== -1
      ? className.substring(colonIndex + 1, className.length)
      : language

  const codeOpenHandler = () => {
    setIsCodeOpen(isCodeOpen => !isCodeOpen)
  }

  return (
    <Highlight
      {...defaultProps}
      code={props.children.props.children.trim()}
      language={language}
      theme={theme}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => {
        return (
          <div className={`pre-wrapper${isCodeOpen ? " prismCodeOpen" : ""}`}>
            <div className="code-tab-wrapper">
              <div className="code-tab">{filename}</div>
            </div>
            <div className="codeOpen-btn-wrap">
              <button onClick={codeOpenHandler}>
                <AiOutlineExpand />
              </button>
            </div>
            <pre className={className} style={style}>
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                  {line.map((token, key) => (
                    <span {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}
            </pre>
          </div>
        )
      }}
    </Highlight>
  )
}

export default PrismWrapper
